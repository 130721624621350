<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">打印机列表</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <!-- 筛选部分 -->
      <!-- 表格头部 -->
	  
	  <!-- 表格头部 -->
	  <!-- 表格-->
      <div class="table_head">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
        <div class="table_head_right">
          <ul>
            <li>
              <el-button size="mini" @click="add()"> 添加 </el-button>
            </li>
          </ul>
          <transition name="el-fade-in">
            <div v-show="show" class="transition-box">
              <el-form
                ref="form"
                :model="labelList"
                label-width="80px"
                size="mini"
              >
                <div class="act_tit">添加打印机配置</div>

                <div class="listcont">
                  <div class="shuai" v-if="admin_type==1">
                    <!-- <div class="selname"></div> -->
                    <el-form-item
                      label="选择商户："
                      label-width="120px"
                      style="margin-top: 5px"
                    >
                    </el-form-item>
                    <el-select v-model="pstoreid" placeholder="请选择商户">
                                            <el-option
                        v-for="item in businessList"
                        :key="item.storeid"
                        :label="item.s_name"
                        :value="item.storeid"
                        @click.native="shopType(item.storeid)"
                      >
                                              </el-option
                      >
                                          </el-select
                    >
               <!--     <Select  placeholder="请选择" class="sel" v-model="selects.storeid">
											
					<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)"></Option>
					 </Select> -->
                  </div>
                <div class="shuai" v-if="admin_type!==3">
                    
                    <el-form-item
                      label="选择门店："
                      label-width="120px"
                      style="margin-top: 5px"
                    >
                    </el-form-item>
                    <el-select v-model="storeid" placeholder="请选择门店">
                                            <el-option
                        v-for="item in selectp"
                        :key="item.s_name"
                        :label="item.s_name"
                        :value="item.storeid"
                        @click.native="storeidType(item.storeid)"
                      >
                                              </el-option
                      >
                                          </el-select
                    >
                  </div>
                </div>
                <el-form-item label="SN号：" label-width="120px">
                  <el-input v-model="snNum" ></el-input>
                </el-form-item>

                <el-form-item label="设备ID：" label-width="120px">
                  <el-input  value="92ea92b8b93044938c3bd4ac0f9a34d1"  :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="设备Key：" label-width="120px">
                  <el-input value="b4d5413406154e3cbba5db7d63ae6520"  :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="打印机URL：" label-width="120px" >
                  <el-input value="https://openapi.sunmi.com/v1/printer/pushContent"  :disabled="true"></el-input>
                </el-form-item>
                <el-form-item size="large">
                  <el-button type="primary" @click="onSubmit"
                    >立即创建</el-button
                  >
                  <el-button @click="show = false">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
          </transition>
        </div>
      </div>
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
        <el-table
          :data="labelList"
          border
          style="width: 85%; margin-left: 200px; text-align: center"
        >
          <el-table-column prop="store_name" label="门店名称" align="center">
          </el-table-column>
          <el-table-column prop="device_no" label="SN号" align="center">
          </el-table-column>
          <el-table-column prop="device_appid" label="设备ID" align="center">
          </el-table-column>
     <el-table-column prop="device_appkey" label="设备Key" align="center">
     </el-table-column>
	 <el-table-column prop="print_url" label="打印机URL" align="center">
	 </el-table-column>

          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <span
                style="margin-left: 10px"
                @click="
                  edit(
                    scope.row.spid,
                    scope.row.storeid,
                    scope.row.store_name,
					scope.row.device_no,
                  )
                "
              >
                编辑
              </span>
              <span
                style="margin-left: 10px"
                @click="delActive(scope.row.spid)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="titlePage"
        >
        </el-pagination>
        <div></div>
      </div>

      <!-- <el-popover placement="end"  width="500" height="500px" trigger="click">
        <el-row :gutter="12" style="border:1px solid red">
          <el-col :span="8">
            <el-card shadow="always"> 总是显示 </el-card>
          </el-col>
        </el-row>

        <el-button slot="reference">click 激活</el-button>
      </el-popover> -->
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "11-6",
      top2: ["11"],
      flag: 1,
      // admin_type: "",
      xstoreid: "",
	  snNum:'',
	  printID:'92ea92b8b93044938c3bd4ac0f9a34d1',
	  printKey:'b4d5413406154e3cbba5db7d63ae6520',
	  printUrl:'https://openapi.sunmi.com/v1/printer/pushContent',
      labelList: [],
      selects: "",
	  admin_type:'',
      selectp: "",
      activeTitle: "",
	  spid:'',
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
				business_id: '', */
      businessList: [{}],
      business_id: "",
      ml_id: "",
      pstoreid: "",
      storeList: [{}],
      storeid: "",
      s_name: "",
      //添加活动
      l_name: "",
      l_count: "",
      l_money: "",
      titlePage: 10,
      sizeForm: {
        labelList: [],
        name: "",
		printList:[],
		
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: 1,
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
    //修改上下架

    //刷新页面按钮
    refresh() {
      location.reload();
    },
  
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
      // this.admin_type = this.$storage.getLocal("admin_type");
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
     this.$request
       .storePrintList({
         token,
     	login_type,
     	storeid:'',
     	page:this.currentpage,
     	limit:this.pageSize
       })
       .then((r) => {
         if (r.code == 0) {
           console.log(r);
           this.titlePage = r.data.count;
           this.labelList = r.data.data;
         }
       });
    },
    delActive(val) {
    console.log(val)
	let login_type = this.$storage.getLocal("type");
	let token = this.$storage.getLocal("token");
	this.$request
	  .storePrintUp({
	    token,
	    storeid: '',
	    login_type: login_type,
	    device_no: '',
	    device_appid: '',
		device_appkey:'',
		print_url:'',
		status:'2',
		spid:val
	  })
	  .then((r) => {
	    // console.log(123)
	    if (r.code == 0) {
	      alert('删除成功')
	      location.reload();
	    }
	  });
    },
    onSubmit() {
      // console.log("222", this.storeid);
      // console.log("333", this.ml_id);
	  // console.log(this.snNum)
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
      if (this.spid == "") {
		  console.log('走1')
        this.$request
          .addStorePrint({
            token,
            // storeid: this.storeid,
            // ml_id:this.ml_id,
            login_type: login_type,
            storeid: this.storeid,
            device_no: this.snNum,
            device_appid: this.printID,
			device_appkey:this.printKey,
			print_url:this.printUrl
          })
          .then((r) => {
            // console.log(123)
            if (r.code == 0) {
              this.show = false;
              location.reload();
            }
          });
		  }else
		  {
			let login_type = this.$storage.getLocal("type");
			let token = this.$storage.getLocal("token");
			this.$request
			// console.log('111',this.spid)
			// return false
			  .storePrintUp({
			    token,
			    storeid: this.storeid,
			    login_type: login_type,
			    device_no: this.snNum,
			    device_appid: this.printID,
			    device_appkey:this.printKey,
			    print_url:this.printUrl,
				status:'1',
				spid:this.spid
			  })
			  .then((r) => {
			    // console.log(123)
			    if (r.code == 0) {
			      // alert('删除成功')
			      location.reload();
			    }
			  });
			  }
 
    },
  add(){
	  this.show=true
	  this.ml_id='',
	  this.snNum='',
	  this.pstoreid='',
	  this.storeid=''
	},
    shopType(value) {
		console.log(value)
		this.pstoreid=value
      let token = this.$storage.getLocal("token");
      this.$request
        .getStoreDataList({
          token,
          pstoreid: value,
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          console.log(123);
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    },
    storeidType(value) {
      this.storeid = value;
    },
    //编辑活动的提交按钮
    onChange() {
      if (this.sizeForm2.title == "") {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
      }
    },
    edit(spid,storeid,store_name,device_no) {
      // console.log
	  console.log(storeid)
	  console.log(store_name)
	  console.log(spid)
      this.show = true;
      this.snNum = device_no;
      this.storeid = storeid;
	  this.s_name=store_name;
	  this.spid=spid
      let token = this.$storage.getLocal("token");
      this.$request
        .getUpStoreData({
          token,
          storeid: storeid,
        })
        .then((r) => {
          // console.log(123)
          if (r.code == 0) {
            console.log(r);
            this.pstoreid = r.data.pstoreid;
            this.$request
              .getStoreDataList({
                token,
                pstoreid: r.data.pstoreid,
                login_type: this.$storage.getLocal("type"),
              })
              .then((r) => {
                console.log(123);
                if (r.code == 0) {
                  // this.storeList = r.data;

                  console.log(r.data);
				  let n = r.data
				  this.selectp=r.data				  // let  sList = []
				  // for(var i = 0; i < n.length;i++){
					 //  console.log(r)
				  // }
                }
              });
          }
        });
      console.log(this.storeid);
    },
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
    let login_type = this.$storage.getLocal("type");
	this.admin_type=login_type
    let token = this.$storage.getLocal("token");
    this.$request
      .storePrintList({
        token,
		login_type,
		storeid:'',
		page:this.currentpage,
		limit:this.pageSize
      })
      .then((r) => {
        if (r.code == 0) {
          console.log(r);
          this.titlePage = r.data.count;
          this.labelList = r.data.data;
        }
      });

    if (login_type == "1") {
      this.$request
        .getMerchantData({
          token,
        })
        .then((r) => {
          if (r.code == 0) {
            this.businessList = r.data;
            console.log(r);
          }
        });
    } else if (login_type == "2") {
      this.$request
        .getStoreDataList({
          token,
          pstoreid: "",
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
			console.log(r)
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}

.left,
.right {
  display: inline;
}

.left {
  float: left;
  margin-left: 210px;
}

.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}

.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}

.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}

.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}

.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}

.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}

.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}

.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}

.block {
  margin-top: 30px;
}

.listcont {
  display: flex;
}

.shuai {
  display: flex;
  /* border:1px solid black */
}

.selname {
  width: 200px;
  border: 1px solid black;
}

.sel {
  /* margin-left: 10px; */
  margin-top: 5px;
}
</style>
